import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const demoRoutes = []

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    ...demoRoutes,
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [{
        name: 'login',
        path: 'login',
        component: () =>
                        import('../components/auth/login/Login.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () =>
                        import('../components/auth/recover-password/RecoverPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [{
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () =>import('../components/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('../components/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('../components/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
      ],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [{
        name: 'dashboard',
        path: 'dashboard',
        component: () =>
                        import('../components/dashboard/Dashboard.vue'),
        default: true,
      },
      {
        name: 'pedidos',
        path: 'pedidos',
        component: () =>
                        import('../components/dashboard/Pedidos.vue'),
        default: true,
      },
      {
        name: 'proceso',
        path: 'proceso',
        component: () =>
                        import('../components/dashboard/PedidoEnProceso.vue'),
        default: true,
      },
      {
        name: 'despachado',
        path: 'despachado',
        component: () =>
                        import('../components/dashboard/PedidoEnDespacho.vue'),
        default: true,
      },
      {
        name: 'nodespachado',
        path: 'nodespachado',
        component: () =>
                        import('../components/dashboard/PedidoNoDespachado.vue'),
        default: true,
      },
      ],
    },
  ],
})
