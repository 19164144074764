<template>
  <nav
    class="app-navbar"
    :style="navbarStyle"
  >
    <div class="app-navbar__content row">
      <div class="app-navbar__menu-container">
        <va-icon-menu
          class="app-navbar__menu"
          v-if="!minimized && !isTopBar"
          @click.native="$emit('update:minimized', !minimized)"
          :color="contextConfig.invertedColor ? $themes.gray : 'black'"
        />

        <va-icon-menu-collapsed
          class="app-navbar__menu"
          v-if="minimized && !isTopBar"
          @click.native="$emit('update:minimized', !minimized)"
          :color="contextConfig.invertedColor ? $themes.gray : 'black'"
        />

        <img class="img-fluid" src="../../../assets/icons/logo-abracol.png" alt="">

        <app-navbar-actions
          class="app-navbar__actions app-navbar__content md5 lg4"
          :user-name="userName"
          :is-top-bar.sync="isTopBarProxy"
        />
      </div>
    </div>
    <div class="app-navbar__shape" :style="shapeStyle">
      <h1 class="app-navbar__title text-center">Portal Transferencias</h1>
    </div>
  </nav>
</template>

<script>
import VaIconMenu from '../../../iconset/VaIconMenu'
import VaIconMenuCollapsed from '../../../iconset/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'
import { colorShiftHsl, ColorThemeMixin } from '../../../services/vuestic-ui'
export default {
  name: 'app-navbar',
  mixins: [ColorThemeMixin],
  inject: ['contextConfig'],
  components: {
    VaIconMenu,
    VaIconMenuCollapsed,
    AppNavbarActions,
  },
  props: {
    isTopBar: {
      type: Boolean,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      userName: '',
      clave: '4893DED7BCCDB7CE81482573D1E50EDA7418AAC5C41DAD2E20E91F1494F7BBB9'
    }
  },
  computed: {
    isTopBarProxy: {
      get () {
        return this.isTopBar
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar)
      },
    },
    minimizedProxy: {
      get () {
        return this.minimized
      },
      set (minimized) {
        this.$emit('update:minimized', minimized)
      },
    },
    navbarStyle () {
      const style = {
        backgroundColor: 'white',
      }
      if (this.contextConfig.gradient) {
        style.backgroundColor = colorShiftHsl(this.$themes.warning, {
          s: -10,
          l: 15,
        }).css
      }
      if (this.contextConfig.shadow === 'sm') {
        style.boxShadow = !this.isTopBar ? '0 2px 3px 0 rgba(52, 56, 85, 0.25)' : null
      }
      return style
    },
    shapeStyle () {
      return {
        borderTopColor: this.contextConfig.gradient ? colorShiftHsl(this.$themes.warning, {
          h: -1,
          s: -11,
          l: 10,
        }).css : 'transparent',
      }
    },
  },
  created () {
    const cryp = localStorage.getItem('ppid')
    const decryptedText = this.CryptoJS.AES.decrypt(cryp, this.clave).toString(this.CryptoJS.enc.Utf8)
    this.userName = decryptedText
  },
}
</script>

<style lang="scss">
  @font-face {
    font-family: 'BebasNeue bold';
    src: url("~@/assets/fonts/BebasNeue-Bold.otf") format("opentype");
  }

  $nav-border-side-width: 3.1875rem;

  .app-navbar {
    transition: background-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
    display: flex;
    padding: 1rem 1rem;
    z-index: 1;

    &__content {
      z-index: 1;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      flex: 1 1 auto;
    }

    &__title {
      top: 0%;
      margin-top: -37px;
      font-size: 22px;
      font-family: 'BebasNeue bold';
    }

    &__center {
      display: flex;
      margin-left: 3rem;
      justify-content: space-between;
      align-items: center;
    }

    &__text {
      color: $lighter-gray;
    }

    &__button {
      width: 10rem;
      margin: 0 0 0 1rem !important;
      font-weight: bold;

      .va-button__content__icon-left.fa-github {
        font-size: 1.5rem;
      }
    }

    &__menu {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      margin-right: 1.5rem;
    }

    &__menu-container {
      display: flex;
      flex-wrap: nowrap;
      height: 2.1rem;
    }

    &__logo {
      width: 9.5rem;
      height: auto;
      align-items: center;

      & * {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &__actions {
      justify-content: flex-end;
    }

    &__mailto-link:hover {
      filter: brightness(85%);
    }

    &__shape {
      transition: border-top-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
      width: 30%;
      max-width: 467px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      border-top: 3rem solid transparent; // hardcoded size
      border-left: $nav-border-side-width solid transparent;
      border-right: $nav-border-side-width solid transparent;
      height: 0;
    }

    @include media-breakpoint-down(lg) {
      &__button {
        display: none !important;
      }
    }

    @include media-breakpoint-down(md) {
      &__center {
        display: none !important;
      }
    }

    @include media-breakpoint-down(sm) {
      &__content {
        align-items: flex-end;
      }

      &__actions {
        margin-top: 4rem;
        justify-content: space-between;
        width: 100%;
      }

      &__shape {
        display: none;
      }
    }
  }
</style>
